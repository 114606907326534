import React, { useState, Fragment, useRef } from 'react';
import ProfileBar from '../profiles/Components/ProfileBar';
import { Dialog, Transition } from '@headlessui/react';
import 'components/formio/formio.css';
import { Form } from '@formio/react';
import form from './form';
import './style.css';
import { useMutation } from 'react-query';
import { uploadSupportFormData } from './services';
import DialogImage from '../assets/img/support_submitted.svg';

function Support() {
  const formRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  function closeModal() {
    setIsOpen(false);
    setSubmissionData({});
  }

  function openModal() {
    setIsOpen(true);
  }

  const submitForm = async (data) => {
    const values = {
      department: data.data.sport,
      description: data.data.description,
      photo: data.data.file ? data.data.file[0]?.file_url : '',
      contact: data.data.phoneNumber,
    };
    await uploadDataMutation.mutateAsync(values);
  };

  const uploadDataMutation = useMutation((data) => uploadSupportFormData(data), {
    onSuccess: () => {
      openModal();
    },
    onError: async (err) => {
      console.debug('Error - ', err);
    },
  });

  return (
    <div>
      <ProfileBar head="Support" act={true} />
      <div className=" m-4 flex flex-col gap-6 rounded-lg border border-gray-350">
        <div
          className={`${isOpen ? 'opacity-20' : null} px-5 py-4 max-w-full font-roboto rounded-lg`}
        >
          <Form
            ref={formRef}
            form={form}
            submission={{ data: submissionData }}
            url={process.env.REACT_APP_API_URL}
            onSubmit={(schema) => {
              submitForm(schema);
            }}
          />
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex justify-center items-center flex-col ">
                    <div className="h-20 w-20 bg-green-50 rounded-full flex justify-center items-center">
                      <img src={DialogImage} className="h-12" alt="img" />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="mt-2 text-center text-xl font-bold text-green-700"
                    >
                      Query Submitted !
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-md text-gray-400">
                        Our support team will contact you soon.
                      </p>
                    </div>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-whiteborder-gray-900 hover:bg-gray-900 border hover:border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

export default Support;
