import { Capacitor } from '@capacitor/core';

const Form = {
  display: 'form',
  components: [
    {
      label: 'Select Department',
      widget: 'choicesjs',
      tableView: true,
      dataSrc: 'url',
      data: {
        url: `/api/method/sfa_act.v1.act.get_functional_area`,
        headers: [
          {
            key: '',
            value: '',
          },
        ],
      },
      dataType: 'string',
      idPath: '',
      validate: {
        required: true,
      },
      key: 'sport',
      type: 'select',
      selectValues: 'message',
      template: '<span>{{ item.name }}</span>',
      valueProperty: 'name',
      input: true,
      disableLimit: false,
      attributes: {
        'data-cy': 'department',
      },
    },
    {
      label: 'Description',
      placeholder: 'Enter description here',
      rows: 7,
      autoExpand: false,
      tableView: true,
      validate: {
        required: true,
      },
      errorLabel: 'Please enter description.',
      key: 'description',
      type: 'textarea',
      input: true,
      attributes: {
        'data-cy': 'description',
      },
    },
    {
      label: 'Contact Number',
      placeholder: 'Personal contact number',
      inputMask: '9999999999',
      tableView: true,
      validate: {
        required: true,
      },
      errorLabel: 'Enter your contact number',
      key: 'phoneNumber',
      type: 'phoneNumber',
      input: true,
      attributes: {
        'data-cy': 'contact_number',
      },
    },
    {
      type: 'button',
      label: 'Submit',
      action: 'submit',
      key: 'submit',
      disableOnInvalid: true,
      input: true,
      tableView: false,
      attributes: {
        'data-cy': 'form_submit',
      },
    },
  ],
};

// Add field if not native platform
if (!Capacitor.isNativePlatform()) {
  Form.components.splice(2, 0, {
    label: 'Upload Photo',
    tableView: false,
    storage: 'customBase64',
    webcam: false,
    fileTypes: [
      {
        label: '',
        value: '',
      },
    ],
    validate: {
      required: true,
    },
    errorLabel: 'Upload photo',
    key: 'file',
    type: 'file',
    url: 'upload_file',
    input: true,
    fileKey: 'file',
    attributes: {
      file_key: 'hello',
      'data-cy': 'form_submit',
    },
  });
}

export default Form;
